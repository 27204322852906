import React from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"

import "./Carousel.css"

// const items = Array.from({length:32},(v,k)=>k+1)
// items.map((i) => <img key={i} src={`/img/Slideshow/${i}.jpg`} className="img-in-slider"/>)

export default function Carousel() {
  const handleOnDragStart = e => e.preventDefault()
  return (
    <AliceCarousel
      mouseTrackingEnabled
      buttonsDisabled={true}
      autoPlay={true}
      autoPlayInterval={2300}
      mouseTrackingEnabled={true}
      disableAutoPlayOnAction={true}
      responsive={{
        0: {
          items: 1,
        },
        400: {
          items: 2,
        },
        500: {
          items: 3,
        },
      }}
    >
      <img
        src="/img/Slideshow/2.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/3.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/32.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />  
      <img
        src="/img/Slideshow/24.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/22.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/9.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/23.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />    
      <img
        src="/img/Slideshow/25.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />
      <img
        src="/img/Slideshow/30.jpg"
        onDragStart={handleOnDragStart}
        className="img-in-slider"
      />  
    </AliceCarousel>
  )
}

import React from "react"

import NavBar from "../components/Header/NavBar"
import style from "./App.module.css"
import Hero from "../components/Hero/Hero"
import Servicios from "../components/Servicios/Servicios"
import Contact from "../components/Contact/Contact"
import Acerca from "../components/Acerca/Acerca"
import MyFooter from "../components/Footer/MyFooter"
import Heroe from "../components/Hero/Heroe"

import { ToastProvider } from "react-toast-notifications"
import Helmet from "react-helmet"

export default function Home() {
  return (
    <ToastProvider placement="bottom-right" autoDismissTimeout="10000">
      <Helmet>
        <title>Lexipack</title>
        <meta name="title" content="Lexipack" />
        <meta
          name="description"
          content="Somos una empresa con enfoques modernos, que nos distingue nuestro excelente servicio, personalizado y éficaz."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lexipack.com.mx/" />
        <meta property="og:title" content="Lexipack" />
        <meta
          property="og:description"
          content="Somos una empresa con enfoques modernos, que nos distingue nuestro excelente servicio, personalizado y éficaz."
        />
        <meta
          property="og:image"
          content="/img/meta.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://lexipack.com.mx/" />
        <meta property="twitter:title" content="Lexipack" />
        <meta
          property="twitter:description"
          content="Somos una empresa con enfoques modernos, que nos distingue nuestro excelente servicio, personalizado y éficaz."
        />
        <meta
          property="twitter:image"
          content="/img/meta.png"
        />
      </Helmet>
      <section id="home">
        {/* <Hero /> */}
        <Heroe />
      </section>

      <section id="acerca">
        <Acerca />
      </section>

      <section>
        <Servicios />
      </section>

      <section id="contacto">
        <Contact />
      </section>

      <MyFooter />
    </ToastProvider>
  )
}

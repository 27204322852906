import React, { useState, useEffect } from "react"

import "./Servicios.css"
import "react-slideshow-image/dist/styles.css"
import { Slide } from "react-slideshow-image"

const slideImages = [
  "/img/Slideshow/slider1.png",
  "/img/Slideshow/slider2.png",
  "/img/Slideshow/slider3.png",
  "/img/Slideshow/slider4.png",
]

export default function Servicios() {
  const [myWindow, setMyWindow] = useState(null)

  const handleResize = e => {
    setMyWindow(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setMyWindow(window.innerWidth)
      window.addEventListener("resize", handleResize)
    }
  })
  
  return (
    <>
      <div className="container-servicios">
        <h3>SERVICIOS</h3>
        <p>
          EN <b>GRUPO</b> LEXIPACK Y SU CONSORCIO DE EMPRESAS CONTAMOS CON LOS
          SIGUIENTES SISTEMAS DE IMPRESIÓN
        </p>
      </div>

      <div class="container">
        <div class="container-inner">
          <img class="img-asset" src="/img/Asset26.svg" />
          <p>
            <b>ROTOGRABADO</b>
          </p>
          <p>Hasta 8 tintas</p>
        </div>
        <div class="container-inner">
          <img src="/img/Asset27.svg" />
          <p>
            <b>FLEXOGRAFÍA</b>
          </p>
          <p>Hasta 10 tintas</p>
        </div>
      </div>
      <div className="container-slider">
        <Slide easing="ease">
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${slideImages[0]})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
          </div>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${slideImages[1]})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
          </div>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${slideImages[2]})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
          </div>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${slideImages[3]})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
          </div>
        </Slide>
      </div>

      <div className="bolsas-header">
        <h3>BOLSAS</h3>
      </div>
      <div className="container-upper-bolsas">
        <div className="container-bolsas">
          <div className="container-bolsa-type">
            <img className="bolsa-img img-tint" src="/img/Asset34.svg" />
            <div className="container-bolsa-info">
              <p className="header-bolsa">MORDAZA</p>
              <p className="bolsa-description">
                Sencilla​, con fuelles laterales​, con perforaciones , con
                mordaza de sello de 1 a 4 cm​.
              </p>
            </div>
          </div>
          <div className="container-bolsa-type">
            <img className="bolsa-img img-tint" src="/img/Asset33.svg" />
            <div className="container-bolsa-info">
              <p className="header-bolsa">LATERAL</p>
              <p className="bolsa-description">
                Simples, con perforaciones, con fuelle de fondo, tipo sobre con
                solapa, tipo sobre con adhesivo, con sello falso wicket.
              </p>
            </div>
          </div>
          <div className="container-bolsa-type">
            <img className="bolsa-img img-tint" src="/img/Asset32.svg" />
            <div className="container-bolsa-info">
              <p className="header-bolsa">STAND UP POUCH</p>
              <p className="bolsa-description">
                Ofrecemos bolsas stand up pouch con zipper, simples, laminadas,
                impresas, impresas laminadas y más.
              </p>
            </div>
          </div>
        </div>
        <img className={myWindow < 1280 ? "remove-display" : "set-display"} src="/img/Asset40.png"></img>
      </div>
    </>
  )
}

import React from "react"

import "./Acerca.css"
import Carousel from "./Carousel"

export default function Acerca() {
  return (
    <div>
      <div className="container-acerca">
        <div className="container-acerca-blue">
          <p>
            Somos una empresa con enfoques modernos, que nos distingue nuestro
            excelente servicio, personalizado y éficaz.
          </p>
        </div>
      </div>
      <div className="carousel-container">
        <Carousel />
        <span id="servicios"></span>
      </div>
    </div>
  )
}

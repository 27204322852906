import React, { useState, useEffect } from "react"
import axios from "axios"
import qs from "qs"
import { ToastProvider, useToasts } from "react-toast-notifications"

import "./Contact.css"

export default function Contact() {
  const { addToast } = useToasts()
  const [myWindow, setMyWindow] = useState(null)
  const [nombre, setNombre] = useState("")
  const [empresa, setEmpresa] = useState("")
  const [ciudad, setCiudad] = useState("")
  const [mensaje, setMensaje] = useState("")

  const handleResize = e => {
    setMyWindow(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setMyWindow(window.innerWidth)
      window.addEventListener("resize", handleResize)
    }
  })

  const handleEmailOut = () => {
    if (nombre && empresa && ciudad && mensaje) {
      addToast("Enviando email, por favor espere", {
        appearance: "info",
        autoDismiss: true,
      })
      axios({
        method: "post",
        // url: "https://secret-reaches-66648.herokuapp.com/contact",
        url: "https://secret-reaches-66648.herokuapp.com/contact",
        data: qs.stringify({
          nombre: nombre,
          empresa: empresa,
          ciudad: ciudad,
          mensaje: mensaje,
        }),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then(resp => {
          addToast("Email enviado", {
            appearance: "success",
            autoDismiss: true,
          })
        })
        .catch(err => {
          addToast("Error al enviar email", {
            appearance: "error",
            autoDismiss: true,
          })
          addToast("Datos incorrectos", {
            appearance: "warning",
            autoDismiss: true,
          })
        })
    } else {
      addToast("Datos incorrectos", {
        appearance: "warning",
        autoDismiss: true,
      })
    }
  }

  return (
    <div className="container-contact">
      <div className="container-outer-contact">
        <div className="container-contact-info">
          <div
            className={
              myWindow < 1000
                ? "remove-display"
                : "set-display container-email-icon"
            }
          >
            <img src="/img/Asset23.svg" />
          </div>
          <div
            className={
              myWindow < 1000
                ? "remove-display"
                : "set-display container-email-separator"
            }
          ></div>
          <div className="container-form">
            <div className="form-info">
              <p>ENVIANOS UN MENSAJE</p>
              <div className="first-row">
                <div className="input-element">
                  <label for="fname">Correo</label>
                  <input
                    type="text"
                    id="nombre"
                    name="fname"
                    onChange={e => {
                      setNombre(e.target.value)
                    }}
                  />
                </div>
                <div className="input-element">
                  <label for="fempresa">Empresa</label>
                  <input
                    type="text"
                    id="empresa"
                    name="fempresa"
                    onChange={e => {
                      setEmpresa(e.target.value)
                    }}
                  />
                </div>
                <div className="input-element">
                  <label for="fciudad">Ciudad</label>
                  <input
                    type="text"
                    id="ciudad"
                    name="fciudad"
                    onChange={e => {
                      setCiudad(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div class="form-message">
              <div className="input-element">
                <label for="fmensaje">Mensaje</label>
                <textarea
                  type="text"
                  id="fmensaje"
                  name="fmensaje"
                  className="message-box"
                  onChange={e => {
                    setMensaje(e.target.value)
                  }}
                />
              </div>
              <input type="submit" value="Submit" onClick={handleEmailOut} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-divisor"></div> */}
      {/* <p>Justo Sierra 815 Col. Agua blanca industrial, Zapopan, Jalisco</p> */}
      {/* <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3734.641515267702!2d-103.42716238453613!3d20.602692386230782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428acf25e2b8fb5%3A0xc416003fe864145b!2sGRUPO%20INDUSTRIAL%20POLIPACK%2C%20S.A.%20DE%20C.V.!5e0!3m2!1sen!2smx!4v1599423165473!5m2!1sen!2smx"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div> */}
      <div className="contact-divisor"></div>
      <div className="outer-video">
        <video src="/img/outer_video.mp4" autoPlay playsinline muted loop />
      </div>
    </div>
  )
}
